<template>
	<div class="van-block">
		<van-cell-group inset class="van-block_card van-card__content--centered">


			<van-circle :current-rate="this.completed_rate"
						:rate="30"
						:speed="100"
						:stroke-width="32"
						:size="'calc(80vw + 10px)'"
						class="van-userpic"
						@click="this.takeShapshoot()"
						:color="{
						  '0%': '#3fecff',
						  '100%': '#6149f6',
						}" >

				<video ref="webcam"
					   autoplay
					   muted
					   playsinline
					   style="display: flex; width: 100%; margin: 0; border-radius: 50%; object-fit: cover;"
					   @click="this.takeShapshoot()">
					Not available
				</video>

				<canvas ref="canvas" style="display: none;"></canvas>

				<van-image round
						   :width="'calc(80vw - 10px)'"
						   :height="'calc(80vw - 10px)'"
						   fit="cover"
						   :src="require('@/assets/images/camera-target.svg')" />

				<van-image v-show="!this.isEmpty(this.shapShootData)"
						   ref="shapshoot"
						   round
						   :width="'calc(80vw - 10px)'"
						   :height="'calc(80vw - 10px)'"
						   fit="cover"
						   :src="this.shapShootData" />


				<div class="van-circle__text">
					{{ this.completed_rate + '%' }}
				</div>

			</van-circle>

		</van-cell-group>
	</div>
</template>

<script>
import CommonService from "@/services/CommonService";

export default {
	name: "CameraView",
	data() {
		return {
			mediaDevices: null,
			mediaStream: null,
			shapShootData: null,
			completed_rate: 0,
			constraints: {
				audio: false,
				video: {
					width: {
						min: 1024,
						ideal: 1280,
						max: 1920
					},
					height: {
						min: 576,
						ideal: 720,
						max: 1080
					},
					facingMode: "environment" // "user" - front camera, "environment" - rear camera
				}
			}
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		async getMediaStream(constraints) {
			console.info(constraints);
			try {
				if (navigator.mediaDevices) {
					this.mediaDevices = navigator.mediaDevices;
					this.mediaStream = await this.mediaDevices.getUserMedia(constraints);
					let video = this.$refs.webcam
					console.info(video);
					video.srcObject = this.mediaStream;
					video.onloadedmetadata = (event) => {

						if (constraints.video.facingMode == 'user') {
							video.style.webkitTransform = "scaleX(-1)";
							video.style.transform = "scaleX(-1)";
						} else {
							video.style.webkitTransform = "scaleX(1)";
							video.style.transform = "scaleX(1)";
						}

						video.play();
						console.info(event);
					};
				} else {
					console.error('MediaDevices - Not support!');
				}
			} catch (err)  {
				console.error(err.message);
			}
		},
		stopCamera() {
			try {
				// stop the current video stream
				if (this.mediaStream != null && this.mediaStream.active) {
					let tracks = this.mediaStream.getVideoTracks();
					console.info(tracks);
					tracks.forEach(track => {
						track.stop();
					})
				}

				// set the video source to null
				this.$refs.webcam.srcObject = null;

			} catch (err)  {
				console.error(err.message);
				alert(err.message);
			}
		},
		async initCamera(mode) {

			try {

				this.stopCamera();

				// change "facingMode"
				if (this.isEmpty(mode)) {
					if (this.constraints.video.facingMode == "environment")
						this.constraints.video.facingMode = "user";
					else
						this.constraints.video.facingMode = "environment"
				} else {
					this.constraints.video.facingMode = mode;
				}

				console.info(this.constraints.video.facingMode);

				// get new media stream
				await this.getMediaStream(this.constraints);
			} catch (err)  {
				console.error(err.message);
				alert(err.message);
			}
		},
		clearShapshoot() {
			let canvas = this.$refs.canvas;
			if (!this.isEmpty(canvas)) {
				let context = canvas.getContext('2d');
				context.fillStyle = "#AAA";
				context.fillRect(0, 0, canvas.width, canvas.height);
			}
			this.shapShootData = null;
		},
		takeShapshoot() {
			let canvas = this.$refs.canvas;
			let video = this.$refs.webcam
			let context = canvas.getContext('2d');

			const height = video.videoHeight;
			const width = video.videoWidth;

			if (width && height) {
				canvas.width = width;
				canvas.height = height;

				if (this.constraints.video.facingMode == "user") {
					context.translate(width, 0);
					context.scale(-1, 1);
				}

				context.drawImage(video, 0, 0, width, height);

				this.shapShootData = canvas.toDataURL('image/png');
				this.$emit('takeShapshoot', this.shapShootData);
				this.emitter.emit('playSound', 'camera');
				setTimeout(() => this.clearShapshoot(), 5000);
			} else {
				this.clearShapshoot();
			}
		}
	},
	mounted() {


		/*var canvas = this.$refs.canvas;
		var context = canvas.getContext('2d');
		let video = this.$refs.webcam;*/


		/*video.onloadedmetadata = (event) => {
			context.translate(video.videoWidth, 0);
			context.scale(-1, 1);
		};

		video.onplay = (event) => {
			const _this = this;
			const loop = () => {
				if (!_this.paused && !_this.ended) {
					context.drawImage(_this, 0, 0);
					setTimeout(loop, 1000 / 300); // drawing at 30fps
				}
				requestAnimationFrame(loop);
			};
			loop();
		};*/

		this.initCamera();
	},
	beforeUnmount() {
		this.stopCamera();
	}
}
</script>

<style scoped>

</style>