<template>
	<EditAbout />
</template>

<script>
import { defineComponent, ref } from 'vue'
import EditAbout from "@/components/forms/EditAbout";
import {useCurrentLang} from "vant";
import CommonService from "@/services/CommonService";
export default defineComponent({
	name: "Profile",
	data() {
		return {
			title: "Edit Profile",
			lang: 'en-US',
			translations: this.$root.intl.translations,
		};
	},
	components: {
		EditAbout
	},
	methods: {
		setPageTitle(title) {
			CommonService.setPageTitle(title);
			this.emitter.emit("setPageTitle", title);
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		toRoute(path, refresh) {

			if (refresh)
				window.location.href = path
			else
				this.$router.push(path);

		},
		translate(string, lang, append, prepend) {

			if (typeof lang !== "undefined") {
				if (!this.isEmpty(this.translations[lang])) {
					if (!this.isEmpty(this.translations[lang][string])) {
						string = this.translations[lang][string];
					}
				}
			}

			if (!this.isEmpty(prepend))
				string = prepend + string;

			if (!this.isEmpty(append))
				string = string + append;

			return string;
		},
	},
	mounted() {
		let _this = this;
		this.lang = useCurrentLang();
		this.setPageTitle(this.translate(this.title, this.lang));
	}
});
</script>