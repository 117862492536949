<template>
	<div class="van-block">
		<van-cell-group inset class="van-block_card van-card__content--centered">

			<CameraView v-if="this.shown.camera_view"
						@takeShapshoot="(data) => this.setUserPic(data)"/>

			<van-circle v-else :current-rate="this.completed_rate"
						:rate="30"
						:speed="100"
						:stroke-width="32"
						:size="'calc(50vw + 10px)'"
						class="van-userpic"
						:color="{
						  '0%': '#3fecff',
						  '100%': '#6149f6',
						}"
						@click="this.shown.userpic_dialog = true">

				<van-image round
						   :width="'calc(50vw - 10px)'"
						   :height="'calc(50vw - 10px)'"
						   fit="cover"
						   :src="(!this.isEmpty(this.form.userpic[0])) ? this.form.userpic[0].url : false" />


				<div class="van-circle__text">
					{{ this.completed_rate + '%' }}
				</div>

			</van-circle>

			<van-uploader ref="uploader"
						  :show-upload="this.shown.uploader"
						  style="display: none"
						  accept="image/x-png,image/jpeg,image/heic"
						  :after-read="(event) => this.setUserPic(event)" />

			<h3 class="van-username">
				Username
				<span>@username</span>
			</h3>

		</van-cell-group>
	</div>

	<div class="van-block">
		<h2 class="van-block_title">{{ this.translate('Personal Information', this.lang) }}</h2>
		<van-cell-group inset class="van-block_card">

			<van-field v-model="this.form.birthday"
					   is-link
					   readonly
					   name="birthday"
					   :label="this.translate('Birthday', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select date', this.lang)"
					   @click="this.shown.pickers.birthday = true" />

			<van-field v-model="this.form.location"
					   is-link
					   readonly
					   name="location"
					   :label="this.translate('Location', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select location', this.lang)"
					   @click="this.shown.pickers.location = true" />

			<van-field v-model="this.form.about"
					   name="about"
					   :label="this.translate('About Me', this.lang)"
					   label-align="top"
					   type="textarea"
					   :placeholder="this.translate('Tell about yourself...', this.lang)"
					   maxlength="255"
					   rows="2"
					   show-word-limit
					   @change="(value) => this.setAndValidate('about', value)" />

			<van-field v-model="this.form.nickname"
					   :label="this.translate('Nickname', this.lang)"
					   :placeholder="this.translate('Type your nick', this.lang)"
					   @change="(value) => this.setAndValidate('nickname', value)" />

			<van-field v-model="this.form.occupation"
					   name="about"
					   :label="this.translate('Occupation', this.lang)"
					   label-align="left"
					   type="text"
					   :placeholder="this.translate('Tell about yourself...', this.lang)"
					   @change="(value) => this.setAndValidate('occupation', value)" />

			<van-field v-model="this.form.religion"
					   is-link
					   readonly
					   name="religion"
					   :label="this.translate('Religion', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.religion = true" />

			<van-field v-model="this.form.childs"
					   is-link
					   readonly
					   name="childs"
					   :label="this.translate('Children', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.childs = true" />

			<van-field v-model="this.form.education"
					   is-link
					   readonly
					   name="education"
					   :label="this.translate('Education', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.education = true" />

			<van-field v-model="this.form.income"
					   is-link
					   readonly
					   name="income"
					   :label="this.translate('Income', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.income = true" />

			<van-field v-model="this.form.smoking"
					   is-link
					   readonly
					   name="smoking"
					   :label="this.translate('Smoking', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.smoking = true" />

			<van-field v-model="this.form.drinking"
					   is-link
					   readonly
					   name="drinking"
					   :label="this.translate('Drinking', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.drinking = true" />

		</van-cell-group>
	</div>

	<div class="van-block">
		<h2 class="van-block_title">{{ this.translate('Physical Features', this.lang) }}</h2>
		<van-cell-group inset class="van-block_card">

			<van-field v-model="this.form.ethnicity"
					   is-link
					   readonly
					   name="ethnicity"
					   :label="this.translate('Ethnicity', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.ethnicity = true" />

			<van-field v-model="this.form.height"
					   is-link
					   readonly
					   name="height"
					   :label="this.translate('Height', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.height = true" />

			<van-field v-model="this.form.body_type"
					   is-link
					   readonly
					   name="body_type"
					   :label="this.translate('Body Type', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.body_type = true" />

			<van-field v-model="this.form.eye_color"
					   is-link
					   readonly
					   name="eye_color"
					   :label="this.translate('Eye Color', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.eye_color = true" />

			<van-field v-model="this.form.hair_color"
					   is-link
					   readonly
					   name="hair_color"
					   :label="this.translate('Hair Color', this.lang)"
					   label-align="left"
					   :placeholder="this.translate('Select item', this.lang)"
					   @click="this.shown.pickers.hair_color = true" />

		</van-cell-group>
	</div>


	<van-radio-group v-model="this.form.relationship" class="van-block">
		<h2 class="van-block_title">{{ this.translate('Relationship', this.lang) }}</h2>
		<van-cell-group inset class="van-block_card" :class="{ 'vertical-scroll': this.collection.relationships.length > 10 }">
			<van-cell v-for="(item, index) of this.collection.relationships"
					  clickable
					  :key="index"
					  :title=" this.translate(item.text, this.lang)"
					  @click="this.setAndValidate('relationship', item.value)">
				<template #right-icon>
					<van-radio :name="'relationship'+index" />
				</template>
			</van-cell>
		</van-cell-group>
	</van-radio-group>

	<van-checkbox-group v-model="this.form.on_site_for" class="van-block">
		<h2 class="van-block_title">{{ this.translate('On the site for', this.lang) }}</h2>
		<van-cell-group inset class="van-block_card" :class="{ 'vertical-scroll': this.collection.on_site_for.length > 10 }">
			<van-cell v-for="(item, index) of this.collection.on_site_for"
					  clickable
					  :key="index"
					  :title=" this.translate(item.text, this.lang)"
					  @click="this.setAndValidate('on_site_for', item.value)">
				<template #right-icon>
					<van-checkbox :name="'on_site_for_'+index"
								  @click.stop />
				</template>
			</van-cell>
		</van-cell-group>
	</van-checkbox-group>

	<van-checkbox-group v-model="this.form.languages" class="van-block">
		<h2 class="van-block_title">{{ this.translate('Languages', this.lang) }}</h2>
		<van-cell-group inset class="van-block_card" :class="{ 'vertical-scroll': this.collection.languages.length > 10 }">
			<van-cell v-for="(item, index) of this.collection.languages"
					  clickable
					  :key="index"
					  :title=" this.translate(item.text, this.lang)"
					  @click="this.setAndValidate('languages', item.value)">
				<template #right-icon>
					<van-checkbox :name="'languages_'+index"
								  @click.stop />
				</template>
			</van-cell>
		</van-cell-group>
	</van-checkbox-group>

	<van-checkbox-group v-model="this.form.interests" class="van-block">
		<h2 class="van-block_title">{{ this.translate('Common interests', this.lang) }}</h2>
		<van-cell-group inset class="van-block_card" :class="{ 'vertical-scroll': this.collection.interests.length > 10 }">
			<van-cell v-for="(item, index) of this.collection.interests"
					  clickable
					  :key="index"
					  :title=" this.translate(item.text, this.lang)"
					  @click="this.setAndValidate('interests', item.value)">
				<template #right-icon>
					<van-checkbox :name="'interests_'+index"
								  @click.stop />
				</template>
			</van-cell>
		</van-cell-group>
	</van-checkbox-group>

	<van-checkbox-group v-model="this.form.sexual_interests" class="van-block">
		<h2 class="van-block_title">{{ this.translate('Sexual interests', this.lang) }}</h2>
		<van-cell-group inset class="van-block_card" :class="{ 'vertical-scroll': this.collection.sexual_interests.length > 10 }">
			<van-cell v-for="(item, index) of this.collection.sexual_interests"
					  clickable
					  :key="index"
					  :title=" this.translate(item.text, this.lang)"
					  @click="this.setAndValidate('sexual_interests', item.value)">
				<template #right-icon>
					<van-checkbox :name="'sexual_interests_'+index"
								  @click.stop />
				</template>
			</van-cell>
		</van-cell-group>
	</van-checkbox-group>

	<van-checkbox-group v-model="this.form.fetishes" class="van-block">
		<h2 class="van-block_title">{{ this.translate('Fetishes', this.lang) }}</h2>
		<van-cell-group inset class="van-block_card" :class="{ 'vertical-scroll': this.collection.fetishes.length > 10 }">
			<van-cell v-for="(item, index) of this.collection.fetishes"
					  clickable
					  :key="index"
					  :title=" this.translate(item.text, this.lang)"
					  @click="this.setAndValidate('fetishes', item.value)">
				<template #right-icon>
					<van-checkbox :name="'fetishes_'+index"
								  @click.stop />
				</template>
			</van-cell>
		</van-cell-group>
	</van-checkbox-group>





	<div class="van-block">
		<h2 class="van-block_title">{{ this.translate('Title', this.lang) }}</h2>
		<van-cell-group inset class="van-block_card">

			<van-cell center title="Title">
				<template #right-icon>
					<van-switch v-model="checked" />
				</template>
			</van-cell>
		</van-cell-group>
	</div>


	<!-- Popups -->
	<van-popup v-model:show="this.shown.pickers.birthday"
			   position="bottom">
		<van-date-picker @confirm="(value) => this.setAndValidate('birthday', value, 'date-picker')"
						 @cancel="this.shown.pickers.birthday = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.religion"
			   position="bottom">
		<van-picker :title="this.translate('Religion', this.lang)"
					:columns="this.collection.religions"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('religion', value, 'picker')"
					@cancel="this.shown.pickers.religion = false" />

	</van-popup>

	<van-popup v-model:show="this.shown.pickers.childs"
			   position="bottom">
		<van-picker :title="this.translate('Children', this.lang)"
					:columns="this.collection.childs"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('childs', value, 'picker')"
					@cancel="this.shown.pickers.childs = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.education"
			   position="bottom">
		<van-picker :title="this.translate('Education', this.lang)"
					:columns="this.collection.education"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('education', value, 'picker')"
					@cancel="this.shown.pickers.education = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.income"
			   position="bottom">
		<van-picker :title="this.translate('Income', this.lang)"
					:columns="this.collection.income"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('income', value, 'picker')"
					@cancel="this.shown.pickers.income = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.smoking"
			   position="bottom">
		<van-picker :title="this.translate('Smoking', this.lang)"
					:columns="this.collection.smoking"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('smoking', value, 'picker')"
					@cancel="this.shown.pickers.smoking = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.drinking"
			   position="bottom">
		<van-picker :title="this.translate('Drinking', this.lang)"
					:columns="this.collection.drinking"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('drinking', value, 'picker')"
					@cancel="this.shown.pickers.drinking = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.ethnicity"
			   position="bottom">
		<van-picker :title="this.translate('Ethnicity', this.lang)"
					:columns="this.collection.ethnicity"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('ethnicity', value, 'picker')"
					@cancel="this.shown.pickers.drinking = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.height"
			   position="bottom">
		<van-picker :title="this.translate('Height', this.lang)"
					:columns="this.collection.height"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('height', value, 'picker')"
					@cancel="this.shown.pickers.drinking = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.body_type"
			   position="bottom">
		<van-picker :title="this.translate('Body Yype', this.lang)"
					:columns="this.collection.body_type"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('body_type', value, 'picker')"
					@cancel="this.shown.pickers.drinking = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.eye_color"
			   position="bottom">
		<van-picker :title="this.translate('Eye Color', this.lang)"
					:columns="this.collection.eye_color"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('eye_color', value, 'picker')"
					@cancel="this.shown.pickers.drinking = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.hair_color"
			   position="bottom">
		<van-picker :title="this.translate('hair_color', this.lang)"
					:columns="this.collection.hair_color"
					@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
					@confirm="(value) => this.setAndValidate('hair_color', value, 'picker')"
					@cancel="this.shown.pickers.drinking = false" />
	</van-popup>

	<van-popup v-model:show="this.shown.pickers.location"
			   position="bottom">
		<van-picker :title="this.translate('Location', this.lang)"
					:columns="this.collection.locations"
					@click-option="(option) => this.locationSelect(option)"
					@scrollInto="(event) => this.playSound('tick', event)"
					@confirm="(value) => this.setAndValidate('location', value, 'picker')"
					@cancel="this.shown.pickers.location = false" />
	</van-popup>

	<!-- Popups -->
	<van-action-sheet v-model:show="this.shown.userpic_dialog"
					  :actions="[
						  {
							  name: 'Camera',
							  value: 'camera'
						  }, {
							  name: 'Upload',
							  value: 'media'
						  }, {
							  name: 'Your photos',
							  subname: 'One of saved early photos',
							  value: 'photos'
						  },
					  ]"
					  cancel-text="Cancel"
					  close-on-click-action
					  @cancel="this.shown.userpic_dialog = false"
					  @select="(action) => {

						  if (action.value == 'camera') {
							  this.shown.camera_view = true;
						  } else if (action.value == 'media') {
							  this.shown.uploader = true;
							  this.$refs.uploader.chooseFile();
						  }

					  }" />

</template>

<script>
import CommonService from "@/services/CommonService";
import {useCurrentLang} from "vant";
import locations from "@/data/locations";
import collections from "@/data/collections";
import {ref} from "vue";
import CameraView from "@/components/common/CameraView";

export default {
	name: "EditAbout",
	components: {
		CameraView
	},
	data() {
		return {
			lang: 'en-US',
			translations: this.$root.intl.translations,
			file_list: [],
			form: {
				userpic: [
					{
						timestamp: null,
						url: 'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg',
						isImage: false,
						isTemp: false
					}
				],
				birthday: null,
				location: null,
				about: null,
				nickname: null,
				occupation: null,
				religion: null,
				childs: null,
				education: null,
				income: null,
				smoking: null,
				drinking: null,
				ethnicity: null,
				height: null,
				body_type: null,
				eye_color: null,
				hair_color: null,
				on_site_for: [],
				languages: [],
				relationship: [],
				interests: [],
				sexual_interests: [],
				fetishes: [],
			},
			collection: {
				locations: [],
				on_site_for: [],
				languages: [],
				relationships: [],
				religions: [],
				childs: [],
				education: [],
				income: [],
				smoking: [],
				drinking: [],
				ethnicity: [],
				height: [],
				body_type: [],
				eye_color: [],
				hair_color: [],
				interests: [],
				sexual_interests: [],
				fetishes: [],
			},
			shown: {
				camera_view: false,
				uploader: false,
				userpic_dialog: false,
				pickers: {
					birthday: false,
					religions: false,
					childs: false,
					location: false,
					education: false,
					income: false,
					smoking: false,
					drinking: false,
					ethnicity: false,
					height: false,
					body_type: false,
					eye_color: false,
					hair_color: false,
				}
			},
			errors: [],
		};
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		toRoute(path, refresh) {

			if (refresh)
				window.location.href = path
			else
				this.$router.push(path);

		},
		translate(string, lang, append, prepend) {

			if (typeof lang !== "undefined") {
				if (!this.isEmpty(this.translations[lang])) {
					if (!this.isEmpty(this.translations[lang][string])) {
						string = this.translations[lang][string];
					}
				}
			}

			if (!this.isEmpty(prepend))
				string = prepend + string;

			if (!this.isEmpty(append))
				string = string + append;

			return string;
		},
		inputValidate(value, input) {

			let message = null;

			if (input == 'email') {
				if (this.isEmpty(value)) {
					message = this.translate('Email is required', this.lang);
				} else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
					message = this.translate('Email syntax is not valid', this.lang);
				}
			}

			if (input == 'password') {
				if (this.isEmpty(value)) {
					message = this.translate('Password is required', this.lang);
				} else if (!(/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}/.test(value))) {
					message = this.translate('Password strength is not valid', this.lang);
				}
			}

			return message;
		},
		setAndValidate(name, value, type) {

			let errors = [];

			if (!this.isEmpty(value)) {

				if (type == 'date-picker') {
					if (this.inArray(this.lang, ['ru-RU', 'uk-UA']))
						value = value.selectedValues.reverse().join('.'); // 31.12.1970
					else
						value = value.selectedValues.join('/'); // 1970/01/31


					if (!this.isEmpty(this.shown.pickers[name]))
						this.shown.pickers[name] = false

				} else if (type == 'picker') {
					value = value.selectedValues.reverse().join(', ');

					if (!this.isEmpty(this.shown.pickers[name]))
						this.shown.pickers[name] = false

				}

				if (typeof (this.form[name]) == "object" && typeof (value) == "string") {
					this.form[name].push(value);
				} else {
					this.form[name] = value;
				}
			}

			console.debug('setAndValidate()', { name: name, value: value, type: type, lang: this.lang, errors: errors });

			if (!this.isEmpty(errors)) {
				this.errors = errors;
				return false;
			}

			if (type == 'switch')
				this.emitter.emit('playSound', 'switch');
			else if (type == 'picker')
				this.emitter.emit('playSound', 'tick');

			return true;
		},
		getSubLocations(country) {
			let _this = this;
			return new Promise(function(resolve, reject) {

				let list = [];
				if (!_this.isEmpty(locations[country])) {
					list = locations[country];
					resolve(list);
				} else {
					reject(false);
				}
			});
		},
		getList(name) {
			let _this = this;
			return new Promise(function(resolve, reject) {

				let list = [];
				if (!_this.isEmpty(collections[name])) {
					list = collections[name];
					resolve(list);
				} else {
					reject(false);
				}
			});
		},
		getLocations() {
			let _this = this;
			return new Promise(function(resolve, reject) {
				const list = [
					{
						text: 'Africa',
						value: 'Africa',
						is_continent: true,
						children: [
							{
								text: _this.translate('-Select-', _this.lang),
								value: null,
								disabled: true
							}, {
								text: 'Algeria',
								value: 'Algeria',
								is_country: true,
								children: []
							}, {
								text: 'Angola',
								value: 'Angola',
								is_country: true,
								children: []
							}, {
								text: 'Benin',
								value: 'Benin',
								is_country: true,
								children: []
							}, {
								text: 'Botswana',
								value: 'Botswana',
								is_country: true,
								children: []
							}, {
								text: 'Burkina Faso',
								value: 'Burkina Faso',
								is_country: true,
								children: []
							}, {
								text: 'Burundi',
								value: 'Burundi',
								is_country: true,
								children: []
							}, {
								text: 'Cabo Verde',
								value: 'Cabo Verde',
								is_country: true,
								children: []
							}, {
								text: 'Cameroon',
								value: 'Cameroon',
								is_country: true,
								children: []
							}, {
								text: 'Central African Republic',
								value: 'Central African Republic',
								is_country: true,
								children: []
							}, {
								text: 'Chad',
								value: 'Chad',
								is_country: true,
								children: []
							}, {
								text: 'Comoros',
								value: 'Comoros',
								is_country: true,
								children: []
							}, {
								text: 'Congo',
								value: 'Congo',
								is_country: true,
								children: []
							}, {
								text: 'Djibouti',
								value: 'Djibouti',
								is_country: true,
								children: []
							}, {
								text: 'Egypt',
								value: 'Egypt',
								is_country: true,
								children: []
							}, {
								text: 'Equatorial Guinea',
								value: 'Equatorial Guinea',
								is_country: true,
								children: []
							}, {
								text: 'Eritrea',
								value: 'Eritrea',
								is_country: true,
								children: []
							}, {
								text: 'Eswatini',
								value: 'Eswatini',
								is_country: true,
								children: []
							}, {
								text: 'Ethiopia',
								value: 'Ethiopia',
								is_country: true,
								children: []
							}, {
								text: 'Gabon',
								value: 'Gabon',
								is_country: true,
								children: []
							}, {
								text: 'Gambia, The',
								value: 'Gambia, The',
								is_country: true,
								children: []
							}, {
								text: 'Ghana',
								value: 'Ghana',
								is_country: true,
								children: []
							}, {
								text: 'Guinea',
								value: 'Guinea',
								is_country: true,
								children: []
							}, {
								text: 'Guinea-Bissau',
								value: 'Guinea-Bissau',
								is_country: true,
								children: []
							}, {
								text: 'Ivory Coast',
								value: 'Ivory Coast',
								is_country: true,
								children: []
							}, {
								text: 'Kenya',
								value: 'Kenya',
								is_country: true,
								children: []
							}, {
								text: 'Lesotho',
								value: 'Lesotho',
								is_country: true,
								children: []
							}, {
								text: 'Liberia',
								value: 'Liberia',
								is_country: true,
								children: []
							}, {
								text: 'Libya',
								value: 'Libya',
								is_country: true,
								children: []
							}, {
								text: 'Madagascar',
								value: 'Madagascar',
								is_country: true,
								children: []
							}, {
								text: 'Malawi',
								value: 'Malawi',
								is_country: true,
								children: []
							}, {
								text: 'Mali',
								value: 'Mali',
								is_country: true,
								children: []
							}, {
								text: 'Mauritania',
								value: 'Mauritania',
								is_country: true,
								children: []
							}, {
								text: 'Mauritius',
								value: 'Mauritius',
								is_country: true,
								children: []
							}, {
								text: 'Morocco',
								value: 'Morocco',
								is_country: true,
								children: []
							}, {
								text: 'Mozambique',
								value: 'Mozambique',
								is_country: true,
								children: []
							}, {
								text: 'Namibia',
								value: 'Namibia',
								is_country: true,
								children: []
							}, {
								text: 'Niger',
								value: 'Niger',
								is_country: true,
								children: []
							}, {
								text: 'Nigeria',
								value: 'Nigeria',
								is_country: true,
								children: []
							}, {
								text: 'Rwanda',
								value: 'Rwanda',
								is_country: true,
								children: []
							}, {
								text: 'Sao Tome and Principe',
								value: 'Sao Tome and Principe',
								is_country: true,
								children: []
							}, {
								text: 'Senegal',
								value: 'Senegal',
								is_country: true,
								children: []
							}, {
								text: 'Seychelles',
								value: 'Seychelles',
								is_country: true,
								children: []
							}, {
								text: 'Sierra Leone',
								value: 'Sierra Leone',
								is_country: true,
								children: []
							}, {
								text: 'Somalia',
								value: 'Somalia',
								is_country: true,
								children: []
							}, {
								text: 'South Africa',
								value: 'South Africa',
								is_country: true,
								children: []
							}, {
								text: 'South Sudan',
								value: 'South Sudan',
								is_country: true,
								children: []
							}, {
								text: 'Sudan',
								value: 'Sudan',
								is_country: true,
								children: []
							}, {
								text: 'Tanzania',
								value: 'Tanzania',
								is_country: true,
								children: []
							}, {
								text: 'Togo',
								value: 'Togo',
								is_country: true,
								children: []
							}, {
								text: 'Tunisia',
								value: 'Tunisia',
								is_country: true,
								children: []
							}, {
								text: 'Uganda',
								value: 'Uganda',
								is_country: true,
								children: []
							}, {
								text: 'Zambia',
								value: 'Zambia',
								is_country: true,
								children: []
							}, {
								text: 'Zimbabwe',
								value: 'Zimbabwe',
								is_country: true,
								children: []
							},
						],
					},
					{
						text: 'Asia',
						value: 'Asia',
						is_continent: true,
						children: [
							{
								text: _this.translate('-Select-', _this.lang),
								value: null,
								disabled: true
							}, {
								text: 'Afghanistan',
								value: 'Afghanistan',
								is_country: true,
								children: []
							}, {
								text: 'Armenia',
								value: 'Armenia',
								is_country: true,
								children: []
							}, {
								text: 'Azerbaijan',
								value: 'Azerbaijan',
								is_country: true,
								children: []
							}, {
								text: 'Bahrain',
								value: 'Bahrain',
								is_country: true,
								children: []
							}, {
								text: 'Bangladesh',
								value: 'Bangladesh',
								is_country: true,
								children: []
							}, {
								text: 'Bhutan',
								value: 'Bhutan',
								is_country: true,
								children: []
							}, {
								text: 'British Indian Ocean Territory',
								value: 'British Indian Ocean Territory',
								is_country: true,
								children: []
							}, {
								text: 'Brunei',
								value: 'Brunei',
								is_country: true,
								children: []
							}, {
								text: 'Cambodia',
								value: 'Cambodia',
								is_country: true,
								children: []
							}, {
								text: 'China',
								value: 'China',
								is_country: true,
								children: []
							}, {
								text: 'Cyprus',
								value: 'Cyprus',
								is_country: true,
								children: []
							}, {
								text: 'Egypt',
								value: 'Egypt',
								is_country: true,
								children: []
							}, {
								text: 'Georgia',
								value: 'Georgia',
								is_country: true,
								children: []
							}, {
								text: 'Hong Kong',
								value: 'Hong Kong',
								is_country: true,
								children: []
							}, {
								text: 'India',
								value: 'India',
								is_country: true,
								children: []
							}, {
								text: 'Indonesia',
								value: 'Indonesia',
								is_country: true,
								children: []
							}, {
								text: 'Iran',
								value: 'Iran',
								is_country: true,
								children: []
							}, {
								text: 'Iraq',
								value: 'Iraq',
								is_country: true,
								children: []
							}, {
								text: 'Israel',
								value: 'Israel',
								is_country: true,
								children: []
							}, {
								text: 'Japan',
								value: 'Japan',
								is_country: true,
								children: []
							}, {
								text: 'Jordan',
								value: 'Jordan',
								is_country: true,
								children: []
							}, {
								text: 'Kazakhstan',
								value: 'Kazakhstan',
								is_country: true,
								children: []
							}, {
								text: 'Kuwait',
								value: 'Kuwait',
								is_country: true,
								children: []
							}, {
								text: 'Kyrgyzstan',
								value: 'Kyrgyzstan',
								is_country: true,
								children: []
							}, {
								text: 'Laos',
								value: 'Laos',
								is_country: true,
								children: []
							}, {
								text: 'Lebanon',
								value: 'Lebanon',
								is_country: true,
								children: []
							}, {
								text: 'Macau',
								value: 'Macau',
								is_country: true,
								children: []
							}, {
								text: 'Malaysia',
								value: 'Malaysia',
								is_country: true,
								children: []
							}, {
								text: 'Maldives',
								value: 'Maldives',
								is_country: true,
								children: []
							}, {
								text: 'Mongolia',
								value: 'Mongolia',
								is_country: true,
								children: []
							}, {
								text: 'Myanmar',
								value: 'Myanmar',
								is_country: true,
								children: []
							}, {
								text: 'Nepal',
								value: 'Nepal',
								is_country: true,
								children: []
							}, {
								text: 'North Korea',
								value: 'North Korea',
								is_country: true,
								children: []
							}, {
								text: 'Oman',
								value: 'Oman',
								is_country: true,
								children: []
							}, {
								text: 'Pakistan',
								value: 'Pakistan',
								is_country: true,
								children: []
							}, {
								text: 'Palestine',
								value: 'Palestine',
								is_country: true,
								children: []
							}, {
								text: 'Philippines',
								value: 'Philippines',
								is_country: true,
								children: []
							}, {
								text: 'Qatar',
								value: 'Qatar',
								is_country: true,
								children: []
							}, {
								text: 'Russia',
								value: 'Russia',
								is_country: true,
								children: []
							}, {
								text: 'Saudi Arabia',
								value: 'Saudi Arabia',
								is_country: true,
								children: []
							}, {
								text: 'Singapore',
								value: 'Singapore',
								is_country: true,
								children: []
							}, {
								text: 'South Korea',
								value: 'South Korea',
								is_country: true,
								children: []
							}, {
								text: 'Sri Lanka',
								value: 'Sri Lanka',
								is_country: true,
								children: []
							}, {
								text: 'Syria',
								value: 'Syria',
								is_country: true,
								children: []
							}, {
								text: 'Taiwan',
								value: 'Taiwan',
								is_country: true,
								children: []
							}, {
								text: 'Tajikistan',
								value: 'Tajikistan',
								is_country: true,
								children: []
							}, {
								text: 'Thailand',
								value: 'Thailand',
								is_country: true,
								children: []
							}, {
								text: 'East Timor',
								value: 'East Timor',
								is_country: true,
								children: []
							}, {
								text: 'Turkey',
								value: 'Turkey',
								is_country: true,
								children: []
							}, {
								text: 'Turkmenistan',
								value: 'Turkmenistan',
								is_country: true,
								children: []
							}, {
								text: 'United Arab Emirates',
								value: 'United Arab Emirates',
								is_country: true,
								children: []
							}, {
								text: 'Uzbekistan',
								value: 'Uzbekistan',
								is_country: true,
								children: []
							}, {
								text: 'Vietnam',
								value: 'Vietnam',
								is_country: true,
								children: []
							}, {
								text: 'Yemen',
								value: 'Yemen',
								is_country: true,
								children: []
							},
						],
					},
					{
						text: 'Europe',
						value: 'Europe',
						is_continent: true,
						children: [
							{
								text: _this.translate('-Select-', _this.lang),
								value: null,
								disabled: true
							}, {
								text: 'Albania',
								value: 'Albania',
								is_country: true,
								children: []
							}, {
								text: 'Andorra',
								value: 'Andorra',
								is_country: true,
								children: []
							}, {
								text: 'Armenia',
								value: 'Armenia',
								is_country: true,
								children: []
							}, {
								text: 'Austria',
								value: 'Austria',
								is_country: true,
								children: []
							}, {
								text: 'Azerbaijan',
								value: 'Azerbaijan',
								is_country: true,
								children: []
							}, {
								text: 'Belarus',
								value: 'Belarus',
								is_country: true,
								children: []
							}, {
								text: 'Belgium',
								value: 'Belgium',
								is_country: true,
								children: []
							}, {
								text: 'Bosnia and Herzegovina',
								value: 'Bosnia and Herzegovina',
								is_country: true,
								children: []
							}, {
								text: 'Bulgaria',
								value: 'Bulgaria',
								is_country: true,
								children: []
							}, {
								text: 'Croatia',
								value: 'Croatia',
								is_country: true,
								children: []
							}, {
								text: 'Cyprus',
								value: 'Cyprus',
								is_country: true,
								children: []
							}, {
								text: 'Czech Republic',
								value: 'Czech Republic',
								is_country: true,
								children: []
							}, {
								text: 'Denmark',
								value: 'Denmark',
								is_country: true,
								children: []
							}, {
								text: 'Estonia',
								value: 'Estonia',
								is_country: true,
								children: []
							}, {
								text: 'Finland',
								value: 'Finland',
								is_country: true,
								children: []
							}, {
								text: 'France',
								value: 'France',
								is_country: true,
								children: []
							}, {
								text: 'Georgia',
								value: 'Georgia',
								is_country: true,
								children: []
							}, {
								text: 'Germany',
								value: 'Germany',
								is_country: true,
								children: []
							}, {
								text: 'Greece',
								value: 'Greece',
								is_country: true,
								children: []
							}, {
								text: 'Hungary',
								value: 'Hungary',
								is_country: true,
								children: []
							}, {
								text: 'Iceland',
								value: 'Iceland',
								is_country: true,
								children: []
							}, {
								text: 'Ireland',
								value: 'Ireland',
								is_country: true,
								children: []
							}, {
								text: 'Italy',
								value: 'Italy',
								is_country: true,
								children: []
							}, {
								text: 'Kazakhstan',
								value: 'Kazakhstan',
								is_country: true,
								children: []
							}, {
								text: 'Latvia',
								value: 'Latvia',
								is_country: true,
								children: []
							}, {
								text: 'Liechtenstein',
								value: 'Liechtenstein',
								is_country: true,
								children: []
							}, {
								text: 'Lithuania',
								value: 'Lithuania',
								is_country: true,
								children: []
							}, {
								text: 'Luxembourg',
								value: 'Luxembourg',
								is_country: true,
								children: []
							}, {
								text: 'Malta',
								value: 'Malta',
								is_country: true,
								children: []
							}, {
								text: 'Moldova',
								value: 'Moldova',
								is_country: true,
								children: []
							}, {
								text: 'Monaco',
								value: 'Monaco',
								is_country: true,
								children: []
							}, {
								text: 'Montenegro',
								value: 'Montenegro',
								is_country: true,
								children: []
							}, {
								text: 'Netherlands',
								value: 'Netherlands',
								is_country: true,
								children: []
							}, {
								text: 'North Macedonia',
								value: 'North Macedonia',
								is_country: true,
								children: []
							}, {
								text: 'Norway',
								value: 'Norway',
								is_country: true,
								children: []
							}, {
								text: 'Poland',
								value: 'Poland',
								is_country: true,
								children: []
							}, {
								text: 'Portugal',
								value: 'Portugal',
								is_country: true,
								children: []
							}, {
								text: 'Romania',
								value: 'Romania',
								is_country: true,
								children: []
							}, {
								text: 'Russia',
								value: 'Russia',
								is_country: true,
								children: []
							}, {
								text: 'San Marino',
								value: 'San Marino',
								is_country: true,
								children: []
							}, {
								text: 'Serbia',
								value: 'Serbia',
								is_country: true,
								children: []
							}, {
								text: 'Slovakia',
								value: 'Slovakia',
								is_country: true,
								children: []
							}, {
								text: 'Slovenia',
								value: 'Slovenia',
								is_country: true,
								children: []
							}, {
								text: 'Spain',
								value: 'Spain',
								is_country: true,
								children: []
							}, {
								text: 'Sweden',
								value: 'Sweden',
								is_country: true,
								children: []
							}, {
								text: 'Switzerland',
								value: 'Switzerland',
								is_country: true,
								children: []
							}, {
								text: 'Turkey',
								value: 'Turkey',
								is_country: true,
								children: []
							}, {
								text: 'Ukraine',
								value: 'Ukraine',
								is_country: true,
								children: []
							}, {
								text: 'United Kingdom',
								value: 'United Kingdom',
								is_country: true,
								children: []
							}, {
								text: 'Vatican City',
								value: 'Vatican City',
								is_country: true,
								children: []
							},
						],
					},
					{
						text: 'North America',
						value: 'North America',
						is_continent: true,
						children: [
							{
								text: _this.translate('-Select-', _this.lang),
								value: null,
								disabled: true
							}, {
								text: 'Antigua and Barbuda',
								value: 'Antigua and Barbuda',
								is_country: true,
								children: []
							}, {
								text: 'Bahamas',
								value: 'Bahamas',
								is_country: true,
								children: []
							}, {
								text: 'Barbados',
								value: 'Barbados',
								is_country: true,
								children: []
							}, {
								text: 'Belize',
								value: 'Belize',
								is_country: true,
								children: []
							}, {
								text: 'Canada',
								value: 'Canada',
								is_country: true,
								children: []
							}, {
								text: 'Costa Rica',
								value: 'Costa Rica',
								is_country: true,
								children: []
							}, {
								text: 'Cuba',
								value: 'Cuba',
								is_country: true,
								children: []
							}, {
								text: 'Dominica',
								value: 'Dominica',
								is_country: true,
								children: []
							}, {
								text: 'Dominican Republic',
								value: 'Dominican Republic',
								is_country: true,
								children: []
							}, {
								text: 'El Salvador',
								value: 'El Salvador',
								is_country: true,
								children: []
							}, {
								text: 'Grenada',
								value: 'Grenada',
								is_country: true,
								children: []
							}, {
								text: 'Guatemala',
								value: 'Guatemala',
								is_country: true,
								children: []
							}, {
								text: 'Haiti',
								value: 'Haiti',
								is_country: true,
								children: []
							}, {
								text: 'Honduras',
								value: 'Honduras',
								is_country: true,
								children: []
							}, {
								text: 'Jamaica',
								value: 'Jamaica',
								is_country: true,
								children: []
							}, {
								text: 'Mexico',
								value: 'Mexico',
								is_country: true,
								children: []
							}, {
								text: 'Nicaragua',
								value: 'Nicaragua',
								is_country: true,
								children: []
							}, {
								text: 'Panama',
								value: 'Panama',
								is_country: true,
								children: []
							}, {
								text: 'Saint Kitts and Nevis',
								value: 'Saint Kitts and Nevis',
								is_country: true,
								children: []
							}, {
								text: 'Saint Lucia',
								value: 'Saint Lucia',
								is_country: true,
								children: []
							}, {
								text: 'Saint Vincent and the Grenadines',
								value: 'Saint Vincent and the Grenadines',
								is_country: true,
								children: []
							}, {
								text: 'Trinidad and Tobago',
								value: 'Trinidad and Tobago',
								is_country: true,
								children: []
							}, {
								text: 'United States of America',
								value: 'United States of America',
								is_country: true,
								children: []
							},
						],
					},
					{
						text: 'Oceania',
						value: 'Oceania',
						is_continent: true,
						children: [
							{
								text: _this.translate('-Select-', _this.lang),
								value: null,
								disabled: true
							}, {
								text: 'Australia',
								value: 'Australia',
								is_country: true,
								children: []
							}, {
								text: 'Fiji',
								value: 'Fiji',
								is_country: true,
								children: []
							}, {
								text: 'Kiribati',
								value: 'Kiribati',
								is_country: true,
								children: []
							}, {
								text: 'Marshall Islands',
								value: 'Marshall Islands',
								is_country: true,
								children: []
							}, {
								text: 'Micronesia',
								value: 'Micronesia',
								is_country: true,
								children: []
							}, {
								text: 'Nauru',
								value: 'Nauru',
								is_country: true,
								children: []
							}, {
								text: 'New Zealand',
								value: 'New Zealand',
								is_country: true,
								children: []
							}, {
								text: 'Palau',
								value: 'Palau',
								is_country: true,
								children: []
							}, {
								text: 'Papua New Guinea',
								value: 'Papua New Guinea',
								is_country: true,
								children: []
							}, {
								text: 'Samoa',
								value: 'Samoa',
								is_country: true,
								children: []
							}, {
								text: 'Solomon Islands',
								value: 'Solomon Islands',
								is_country: true,
								children: []
							}, {
								text: 'Tonga',
								value: 'Tonga',
								is_country: true,
								children: []
							}, {
								text: 'Tuvalu',
								value: 'Tuvalu',
								is_country: true,
								children: []
							}, {
								text: 'Vanuatu',
								value: 'Vanuatu',
								is_country: true,
								children: []
							},
						],
					},
					{
						text: 'South America',
						value: 'South America',
						is_continent: true,
						children: [
							{
								text: _this.translate('-Select-', _this.lang),
								value: null,
								disabled: true
							}, {
								text: 'Argentina',
								value: 'Argentina',
								is_country: true,
								children: []
							}, {
								text: 'Bolivia',
								value: 'Bolivia',
								is_country: true,
								children: []
							}, {
								text: 'Brazil',
								value: 'Brazil',
								is_country: true,
								children: []
							}, {
								text: 'Chile',
								value: 'Chile',
								is_country: true,
								children: []
							}, {
								text: 'Colombia',
								value: 'Colombia',
								is_country: true,
								children: []
							}, {
								text: 'Ecuador',
								value: 'Ecuador',
								is_country: true,
								children: []
							}, {
								text: 'Guyana',
								value: 'Guyana',
								is_country: true,
								children: []
							}, {
								text: 'Paraguay',
								value: 'Paraguay',
								is_country: true,
								children: []
							}, {
								text: 'Peru',
								value: 'Peru',
								is_country: true,
								children: []
							}, {
								text: 'Suriname',
								value: 'Suriname',
								is_country: true,
								children: []
							}, {
								text: 'Uruguay',
								value: 'Uruguay',
								is_country: true,
								children: []
							}, {
								text: 'Venezuela',
								value: 'Venezuela',
								is_country: true,
								children: []
							},
						],
					},
				];
				resolve(list);
			})
		},
		locationSelect(option) {
			if (!this.isEmpty(option.currentOption.is_country)) {
				if (option.currentOption.is_country === true) {
					let country = option.currentOption.value
					this.getSubLocations(country).then((list) => option.currentOption.children = list);
				}
			}
		},
		setUserPic(data) {

			if (data.file) {
				if (!this.isEmpty(data.file.name)) {
					this.form.userpic[0].url = data.content;
				}
			} else {
				this.form.userpic[0].url = data;
			}

			console.log(this.form.userpic[0].url);

			setTimeout(() => {
				this.shown.camera_view = false;
				this.shown.uploader = false;
			}, 1000);
		},
		onSubmit() {
			console.info('onSubmit()');
			this.errors.push(this.translate('Password is not valid! Try again or restore it.', this.lang));
			this.has_password_error = true;
			setTimeout(() => {
				this.errors = [];
			}, 5000);
		}
	},
	mounted() {
		this.lang = useCurrentLang();
		this.getLocations().then((list) => this.collection.locations = list);
		this.getList('on_site_for').then((list) => this.collection.on_site_for = list);
		this.getList('languages').then((list) => this.collection.languages = list);
		this.getList('relationships').then((list) => this.collection.relationships = list);
		this.getList('interests').then((list) => this.collection.interests = list);
		this.getList('sexual_interests').then((list) => this.collection.sexual_interests = list);
		this.getList('fetishes').then((list) => this.collection.fetishes = list);
		this.getList('religions').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.religions = list;
		});
		this.getList('childs').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.childs = list;
		});
		this.getList('education').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.education = list;
		});
		this.getList('income').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.income = list;
		});
		this.getList('smoking').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.smoking = list;
		});
		this.getList('drinking').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.drinking = list;
		});
		this.getList('ethnicity').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.ethnicity = list;
		});
		this.getList('height').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.height = list;
		});
		this.getList('body_type').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.body_type = list;
		});
		this.getList('eye_color').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.eye_color = list;
		});
		this.getList('hair_color').then((list) => {
			list.forEach(item => item.text = this.translate(item.text, this.lang));
			this.collection.hair_color = list;
		});
	},
	computed: {
		completed_rate() {
			let {filled, total} = CommonService.countTotals(this.form);
			let percentage = Math.round(Math.abs((filled/total) * 100), 2);

			if (percentage < 0)
				percentage = 0;
			else if (percentage > 100)
				percentage = 100;

			return percentage;
		}
	},
}
</script>

<style scoped>

</style>