export default {
	"on_site_for": require('@/data/collections/on_site_for.json'),
	"languages": require('@/data/collections/languages.json'),
	"relationships": require('@/data/collections/relationships.json'),
	"religions": require('@/data/collections/religions.json'),
	"childs": require('@/data/collections/childs.json'),
	"education": require('@/data/collections/education.json'),
	"income": require('@/data/collections/income.json'),
	"smoking": require('@/data/collections/smoking.json'),
	"drinking": require('@/data/collections/drinking.json'),
	"ethnicity": require('@/data/collections/ethnicity.json'),
	"height": require('@/data/collections/height.json'),
	"body_type": require('@/data/collections/body_type.json'),
	"eye_color": require('@/data/collections/eye_color.json'),
	"hair_color": require('@/data/collections/hair_color.json'),
	"interests": require('@/data/collections/interests.json'),
	"sexual_interests": require('@/data/collections/sexual_interests.json'),
	"fetishes": require('@/data/collections/fetishes.json'),


	"color_schemes": require('@/data/collections/color_schemes.json'),
};